import { axios } from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 *
 * @param parameter
 * @returns {*}
 */

// // 资讯列表
export function articlelist(parameter) {
  return axios({
    url: '/api-news/public/article',
    method: 'get',
    params: parameter
  })
}

// 资讯詳情
// export function articleDetail (parameter) {
//   return axios({
//     url: '/api-news/public/article/' + parameter,
//     method: 'get'
//     // data: parameter
//   })
// }

/**
 * 获取分类根节点
 * @param {} parameter
 * @returns
 */
export function getRootNodeList(parameter) {
  return axios({
    url: '/home/tbColumn/rootList',
    method: 'get',
    params: parameter
  })
}

/**
 * 分类子节点列表
 * @param {*} parameter
 * @returns
 */
export function getTreeChildListBatch(parameter) {
  return axios({
    url: '/home/tbColumn/getChildListBatch',
    method: 'get',
    params: parameter
  })
}

/**
 * 新闻列表
 * @param {*} parameter
 * @returns
 */
export function getNewsList(parameter) {
  return axios({
    url: '/home/tbHomeNews/list',
    method: 'get',
    params: parameter
  })
}

// 资讯详情
export function articleDetail(parameter) {
  return axios({
    url: '/home/tbHomeNews/public/detail/' + parameter,
    method: 'get'
  })
}

// 资讯详情
export function articleDetailWithToken(parameter) {
  return axios({
    url: '/home/tbHomeNews/detail/' + parameter,
    method: 'get'
  })
}
