<template>
  <div>
    <div class="swiper">
      <mt-swipe class="spacing" :auto="4000">
        <mt-swipe-item v-for="(img, index) in swipeList" :key="index">
          <div>
            <img :src="img.slideshowPath" />
          </div>
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <div class="iconList">
      <ul>
        <li @click="repair">
          <img src="../../assets/img/property/repair.png" />
          <p>设备报修</p>
        </li>
        <li @click="cost">
          <img src="../../assets/img/property/property.png" />
          <p>物业费</p>
        </li>
        <li @click="demand">
          <img src="../../assets/img/property/water.png" />
          <p>水电费</p>
        </li>
        <li @click="setting">
          <img src="../../assets/img/property/setting.png" />
          <p>账号设置</p>
        </li>
      </ul>
    </div>
    <Announcement></Announcement>
  </div>
</template>
<script>
import Announcement from '@/components/Announcement/index'
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

const bannerOne = require('@/assets/img/property/banner.png')
const bannerTwo = require('@/assets/img/property/banner.png')
const bannerThree = require('@/assets/img/property/banner.png')

export default {
  name: 'Home',
  components: { Announcement },
  data() {
    return {
      swipeList: [
        {
          id: 1,
          slideshowPath: bannerOne
        },
        {
          id: 2,
          slideshowPath: bannerTwo
        },
        {
          id: 3,
          slideshowPath: bannerThree
        }
      ]
    }
  },
  mounted() {
    const token = getCookie(ACCESS_TOKEN)
    if (!token) {
      this.$router.replace({
        name: 'login'
      })
    }
    // history.pushState(null, null, document.URL)
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL)
    // })
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.resolveBack, false) // false阻止默认事件
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.resolveBack, false)
  },
  methods: {
    resolveBack() {
      WeixinJSBridge.call('closeWindow')
    },
    repair() {
      this.$router.push({
        name: 'needRepairList'
      })
    },
    cost() {
      this.$router.push({
        name: 'propertyFee'
      })
    },
    demand() {
      this.$router.push({
        name: 'utilityBills'
      })
    },
    setting() {
      this.$router.push({
        path: '/settings/home'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.iconList {
  ul {
    padding: 0 2px;
    background: #fff;
    margin-bottom: 0;

    li {
      width: 25%;
      float: left;
      padding: 18px 0px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin-bottom: 0;
        font-size: 13px;
        color: #333;
        font-family: 'Source Han Sans CN';
      }
    }
  }
}

.swiper {
  height: 161px;

  img {
    width: 375px;
    height: 161px;
  }
}
</style>
