<template>
  <div class="base">
    <div class="line"></div>
    <div class="inforList">
      <p class="title">
        <span class="left">园区公告</span>
      </p>
      <div class="list">
        <div
          class="listCt"
          v-for="(item, index) in listArr"
          :key="index"
          @click="inforDetail(item.id, item.columnId_dictText)"
        >
          <div class="listLeft">
            <img v-if="item.imagePath" :src="item.imagePath + '?x-oss-process=image/resize,h_100'" />
          </div>
          <div class="listRight">
            <p class="gg_title">{{ item.title }}</p>
            <p class="gg_time"><img class="time_img" src="@/assets/img/park/date.png" />{{ item.createTime }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNewsList } from '@/api/news'

export default {
  components: {},
  data() {
    return {
      pageNo: 1, // 分页页数
      listArr: []
    }
  },
  mounted() {
    this.getInfor()
  },
  filters: {
    newsCreateTime(val) {
      return val.substring(0, 10)
    }
  },
  methods: {
    // 详情页
    inforDetail(id, title) {
      this.$router.push({
        name: 'news-detail',
        query: { id: id, title: title }
      })
    },
    getInfor() {
      getNewsList({
        pageSize: 5,
        pageNo: this.pageNo,
        columnId: this.sourceType,
        column: 'createTime',
        field: 'id,,,title,viewNum,columnName,source_dictText,createBy,createTime,action',
        order: 'desc',
        checkStatus: '2',
        contentType: '新闻',
        columnName: '公告消息'
      })
        .then((res) => {
          this.listArr = res.result.records
        })
        .catch((err) => {
          this.requestFailed(err)
        })
    }
  }
}
</script>
<style lang="less" scoped>
.base {
  padding: 15px 0;

  .line {
    height: 10px;
    background: #f6f8fcff;
    margin-bottom: 15px;
  }

  .inforList {
    background: #fff;

    .title {
      margin-bottom: 0;
      padding: 0px 15px 15px;

      .left {
        color: #333;
        font-weight: bold;
        font-size: 15px;
      }
    }

    .list {
      padding: 0px 15px 15px;

      .listCt {
        padding-bottom: 18px;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 18px;

        .listRight {
          width: 220px;
          margin-left: 26px;
          display: inline-block;

          .gg_title {
            color: #333;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
          }

          .gg_time {
            font-size: 12px;
            color: #999;
            margin-top: 5px;
            font-family: 'Arial';
            display: flex;
            align-items: center;

            .time_img {
              margin-right: 6px;
              width: 10px;
              height: 10px;
            }
          }
        }

        .listLeft {
          width: 90px;
          display: inline-block;
          vertical-align: top;
          border-radius: 5px;
          overflow: hidden;
          height: 65px;

          background: url(~@/assets/img/picture_default.png) no-repeat center center;
          background-size: cover;

          img {
            width: 90px;
            height: 65px;
          }
        }
      }

      .none {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
