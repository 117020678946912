var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base" }, [
    _c("div", { staticClass: "line" }),
    _c("div", { staticClass: "inforList" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.listArr, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "listCt",
              on: {
                click: function($event) {
                  return _vm.inforDetail(item.id, item.columnId_dictText)
                }
              }
            },
            [
              _c("div", { staticClass: "listLeft" }, [
                item.imagePath
                  ? _c("img", {
                      attrs: {
                        src:
                          item.imagePath + "?x-oss-process=image/resize,h_100"
                      }
                    })
                  : _vm._e()
              ]),
              _c("div", { staticClass: "listRight" }, [
                _c("p", { staticClass: "gg_title" }, [
                  _vm._v(_vm._s(item.title))
                ]),
                _c("p", { staticClass: "gg_time" }, [
                  _c("img", {
                    staticClass: "time_img",
                    attrs: { src: require("@/assets/img/park/date.png") }
                  }),
                  _vm._v(_vm._s(item.createTime))
                ])
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "title" }, [
      _c("span", { staticClass: "left" }, [_vm._v("园区公告")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }